var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "mx-0" },
        [
          _c(
            "v-flex",
            {
              staticClass: "text-left xs1",
              staticStyle: { padding: "1.4vh 0 !important" },
            },
            [
              _vm.$route.path !== "/dashboard"
                ? _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            icon: "",
                            color:
                              _vm.$route.path !== "/dashboard"
                                ? "rgba(0, 0, 0, 0.54)"
                                : "transparent",
                            small: "",
                          },
                          on: { click: _vm.goBack },
                        },
                        [_c("v-icon", [_vm._v(_vm._s("mdi-arrow-left-bold"))])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-flex",
            {
              staticClass: "mt-1 xs10 mainboxdesignfix",
              class:
                _vm.timerData.isTimeRunning && _vm.$route.path !== "/stemp"
                  ? _vm.$route.path === "/dashboard"
                    ? ""
                    : "xs10"
                  : _vm.$route.path === "/dashboard"
                  ? ""
                  : "xs10",
            },
            [
              _c(
                "v-flex",
                { staticClass: "boxoflogo" },
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c("v-img", {
                        attrs: {
                          height: "40",
                          contain: "",
                          src: require("../assets/" + _vm.logoUrl),
                        },
                        on: { click: _vm.navigateToDashboard },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a",
                    {
                      staticStyle: { "text-decoration": "none" },
                      on: { click: _vm.navigateToDashboard },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-center mt-0 orange--text mb-0 font-weight-bold text-body-2",
                        },
                        [_vm._v(_vm._s(_vm.getAppHost))]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  staticClass: "text-right timerdesign",
                  class:
                    _vm.timerData.isTimeRunning && _vm.$route.path !== "/stemp"
                      ? "pr-1"
                      : "",
                },
                [
                  _vm.isAuthenticated && _vm.$route.path !== "/stemp"
                    ? _c("timer")
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            { staticClass: "my-auto fix__icon__design", attrs: { xs1: "" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "mr-7",
                  attrs: { large: "" },
                  on: {
                    click: function ($event) {
                      return _vm.refreshContentUpdate()
                    },
                  },
                },
                [_vm._v("mdi-refresh-circle")]
              ),
              _vm.checkFeatureEnabled("tasks")
                ? [
                    _c(
                      "router-link",
                      { attrs: { to: "/tasks" } },
                      [
                        _c(
                          "v-badge",
                          {
                            attrs: {
                              left: "",
                              content: _vm.tasksCount,
                              overlap: "",
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-calendar-check")])],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c("v-divider", { staticClass: "mx-1 grey darken-1" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }